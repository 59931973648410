import styled from "styled-components"
import NumbersCard from "../components/NumbersCard"
import Parse from "parse"
import { useEffect } from "react"
import { useState } from "react"
import { useMemo } from "react"
import TableScans from "../components/TableScans"
import { products } from "../products"
import { Button, Paper } from "@material-ui/core"

Parse.initialize(process.env.REACT_APP_PARSE_USERNAME, process.env.REACT_APP_PARSE_PASSWORD)
Parse.serverURL = process.env.REACT_APP_PARSE_URL

const VisitorByQR = Parse.Object.extend("visitorByQr")
const VisitorByPage = Parse.Object.extend("visitorByPage")
const MessageContact = Parse.Object.extend("messagesContact")

const keysMessageNames = {
    name: "Nombre y Apellido",
    mail: "Correo",
    phone: "Telefono",
    company: "Empresa",
    country: "Pais",
    message: "Mensaje",
    createdAt: "Fecha",
}

const Container = styled.div`
    position: relative;
    max-width: 800px;
    margin: 0 auto;

    & > * {
        margin-top: 30px;
    }

    .buttonsContainer {
        border-radius: 25px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .exportButton {
            margin: 0 20px;
        }
    }
`

function processPageData(data, object) {
    object.brochuresview = [[], []]
    object.brochureDownloads = []
    object.brochureDownloadIndex = {}
    object.brochureDownloadCount = 0
    object.brochureDownloadViews = 0

    products.forEach((product, index) => {
        object.brochureDownloadIndex[product] = index
        object.brochureDownloads[index] = {
            id: index,
            name: product,
            count: 0,
            views: 0,
        }
    })

    object.whatsappSupport = []
    object.mailSupport = []

    let id = object.brochureDownloadIndex.length

    for (let i = 0; i < data.pagedata.length; i++) {
        const current = data.pagedata[i]
        const pathSplit = current.page.split("/")

        switch (pathSplit[1]) {
            case "whatsapp":
                if (pathSplit[2]) {
                    object.whatsappSupport.push(pathSplit[2])
                } else {
                    object.whatsappSupport.push("Directo")
                }

                break
            case "mail":
                if (pathSplit[2]) {
                    object.mailSupport.push(pathSplit[2])
                }
                break
            case "download":
                if (pathSplit[2]) {
                    const name = pathSplit[2]

                    if (object.brochureDownloadIndex[name] === undefined) {
                        object.brochureDownloadIndex[name] = id++
                    }

                    const index = object.brochureDownloadIndex[name]

                    const brochure = object.brochureDownloads[index]
                    if (!brochure) {
                        object.brochureDownloads[index] = {
                            id: index,
                            name,
                            count: 0,
                            views: 0,
                        }
                    }

                    object.brochureDownloads[index].count++
                    object.brochureDownloadCount++
                }
                break
            default:
                if (pathSplit[1] === "es" || pathSplit[1] === "en") {
                    const type = pathSplit[2]
                    const name = current.text ?? "Servicios"

                    if (type === "products" || type === "services") {
                        if (name) {
                            if (object.brochureDownloadIndex[name] === undefined) {
                                object.brochureDownloadIndex[name] = id++
                            }

                            const index = object.brochureDownloadIndex[name]

                            const brochure = object.brochureDownloads[index]
                            if (!brochure) {
                                object.brochureDownloads[index] = {
                                    id: index,
                                    name,
                                    count: 0,
                                    views: 0,
                                }
                            }

                            object.brochureDownloads[index].views++
                            object.brochureDownloadViews++
                        }
                    }
                }
                break
        }
    }
}

const columnsDownloads = [
    {
        field: "name",
        headerName: "Brochure",
        width: 250,
    },
    {
        field: "views",
        headerName: "Visualizaciones",
        type: "number",
        width: 250,
    },
    {
        field: "count",
        headerName: "Descargas",
        type: "number",
        width: 250,
    },
]

const columnsCountry = [
    {
        field: "country",
        headerName: "País",
        width: 250,
    },
    {
        field: "city",
        headerName: "Ciudad",
        width: 250,
    },
    {
        field: "count",
        headerName: "Total",
        type: "number",
        width: 250,
    },
]

const Main = () => {
    const [data, setData] = useState()

    const processData = useMemo(() => {
        let id = 0
        if (data) {
            const result = {}
            result.scandata = {}
            result.qrscan = data.qrdata

            result.cityIndex = {}
            result.scanByCity = []

            data.qrdata.forEach((v) => {
                const city = v.location.city

                if (city) {
                    if (result.cityIndex[city] === undefined) {
                        result.cityIndex[city] = id++
                    }

                    const index = result.cityIndex[city]

                    if (!result.scanByCity[index]) {
                        result.scanByCity[index] = {
                            id: index,
                            city: city,
                            country: v.location.name,
                            count: 0,
                        }
                    }

                    result.scanByCity[index].count++
                }
            })

            processPageData(data, result)
            return result
        }
    }, [data])

    useEffect(() => {
        async function getData() {
            const qrQuery = new Parse.Query(VisitorByQR)
            let qrdata = await qrQuery.find()
            qrdata = qrdata.map((v) => v.attributes)

            const pageQuery = new Parse.Query(VisitorByPage)
            let pagedata = await pageQuery.find()
            pagedata = pagedata.map((v) => v.attributes)

            const messageQuery = new Parse.Query(MessageContact)
            let messagedata = await messageQuery.find()
            messagedata = messagedata.map((v) => v.attributes)

            setData({
                qrdata,
                pagedata,
                messagedata,
            })
        }

        getData()
    }, [])

    const numbers1 = [
        {
            name: "QR Escaneado",
            value: processData?.qrscan.length,
        },
        {
            name: "Brochures vistos",
            value: processData?.brochureDownloadViews
        },
        {
            name: "Brochures descargados",
            value: processData?.brochureDownloadCount,
        },
    ]

    const numbers2 = [
        {
            name: "Consultas por Whatsapp",
            value: processData?.whatsappSupport.length,
        },
        {
            name: "Consultas por Mail",
            value: processData?.mailSupport.length,
        },
        {
            name: "Consultas por Contacto",
            value: data?.messagedata.length,
        },
    ]

    return (
        <Container>
            <NumbersCard data={numbers1} />
            <NumbersCard data={numbers2} />

            <TableScans
                title="Cantidad de visitantes por ubicación"
                columns={columnsCountry}
                rows={processData?.scanByCity}
            />
            <TableScans
                height={"760px"}
                title="Brochures descargados"
                columns={columnsDownloads}
                rows={processData?.brochureDownloads}
                pageSize={11}
            />
            <Paper
                className="buttonsContainer"
                style={{ height: 100, marginBottom: 30 }}
                elevation={3}
            >
                <Button
                    className="exportButton"
                    variant="contained"
                    onClick={() => {
                        const element = document.createElement("a")
                        element.href =
                            "data:text/csv;chatset=unicode," +
                            escape(
                                data.messagedata.reduce((a, c, index) => {
                                    let firstLine = ""
                                    let line = ""

                                    const keys = Object.keys(c)
                                    for (let i = 0; i < keys.length - 1; i++) {
                                        const key = keys[i]

                                        if (index === 0) {
                                            firstLine +=
                                                keysMessageNames[key] +
                                                (i < keys.length - 2 ? ";" : "")
                                        }

                                        line += c[key] + (i < keys.length - 2 ? ";" : "")
                                    }

                                    if (firstLine !== "") firstLine += "\r\n"
                                    line += "\r\n"
                                    return a + firstLine + line
                                }, "")
                            )
                        element.setAttribute("download", "mensajes.csv")
                        element.click()
                    }}
                >
                    Exportar mensajes
                </Button>
            </Paper>
        </Container>
    )
}

export default Main
