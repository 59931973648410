import styled from "styled-components"

export const NumbersContainer = styled.div`
    position: realtive;

    width: 100%;
    height: 150px;

    #background {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 25px;
    }
`

export const NumberDataContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 0 30px;

    .text {
        font-size: 15px;
        color: #848a8c;
        font-weight: bold;
    }

    .number {
        font-size: 32px;
        margin-top: 10px;
        font-weight: bold;
    }
`
