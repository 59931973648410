import { Paper } from "@material-ui/core"
import { DataGrid } from "@material-ui/data-grid"
import { TableContainer } from "./TableScan.style"

const TableScans = ({ rows = [], columns = [], title, pageSize = 5, height }) => {
    return (
        <TableContainer>
            <Paper
                className="tableContainer"
                style={{ height: height ? height : "" }}
                elevation={3}
            >
                {title && <h2>{title}</h2>}
                <DataGrid
                    style={{ height: title ? "" : "100%" }}
                    className="table"
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    disableSelectionOnClick
                />
            </Paper>
        </TableContainer>
    )
}

export default TableScans
