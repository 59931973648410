import styled from "styled-components"

export const TableContainer = styled.div`
    .tableContainer {
        width: 100%;
        height: 460px;
        overflow: hidden;
        border-radius: 25px;

        h2 {
            color: #848a8c;
            margin-left: 20px;
            margin-bottom: 5px;
            margin-top: 20px;
        }
    }

    .MuiDataGrid-root {
        border: none;
        padding: 10px;
        height: calc(100% - 60px);
    }
`
