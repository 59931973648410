export const products = [
    "Servicios",
    "Productos",
    "SBM 21 BX",
    "SBM 400SR",
    "SBM 300",
    "SBM 400PL",
    "SBM 400SP",
    "SBM 700",
    "SBM 700SR",
    "SBM 800",
    "SBM 800XP",
]