import { Paper } from "@material-ui/core"
import { NumberDataContainer, NumbersContainer } from "./NumbersCard.style"

const NumbersCard = ({ data }) => {
    return (
        <NumbersContainer>
            <Paper id="background" elevation={3}>
                {data.map((d, index) => (
                    <NumberDataContainer key={index}>
                        <div className="text">{d.name}</div>
                        <div className="number">
                            {isNaN(d.value) || isNaN(d.value) === undefined
                                ? "Cargando..."
                                : d.value}
                        </div>
                    </NumberDataContainer>
                ))}
            </Paper>
        </NumbersContainer>
    )
}

export default NumbersCard
