import "./App.css"
import { ThemeProvider, createTheme, CssBaseline } from "@material-ui/core"
import Main from "./pages/Main"

const theme = createTheme({
    palette: {
        type: "dark",
    },
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Main />
        </ThemeProvider>
    )
}

export default App
